import React from 'react'
import ApexCharts from 'react-apexcharts';
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import './polarChart.css'

export default function ApexPolarCharts({ series, icon, title, description, legends }) {
    const options = {
        chart: {
            type: 'polarArea',
            toolbar: {
                show: false,
            },
        },
        series: [...series],
        labels: [...legends],
        stroke: {
            colors: ['#fff'],
        },
        fill: {
            opacity: 0.8,
        },
        tooltip: {
            enabled: true,
            shared: true,
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            floating: false,
            fontSize: '14px',

        },
        responsive: [
            {
                breakpoint: 2000, // For screens less than 1024px
                options: {
                    chart: {
                        width: 400,
                        height: 350,
                    },
                },
            },
            {
                breakpoint: 1024, // For screens less than 1024px
                options: {
                    chart: {
                        width: 400,
                        height: 330,
                    },
                },
            },
            {
                breakpoint: 892,
                options: {
                    chart: {
                        width: 300,
                        height: 340,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        },
                    },
                },
            },
            {
                breakpoint: 768,
                options: {
                    chart: {
                        width: 400,
                        height: 360,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        },
                    },
                },
            },
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 280,
                        height: 350,
                    },
                },
            },
        ],
    };
    return (
        <Card>
            <MDBox py={2} pr={2} pl={icon.component ? 1 : 2} height="400px">
                <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
                    {icon.component && (
                        <MDBox
                            width="4rem"
                            height="4rem"
                            bgColor={icon.color || "dark"}
                            variant="gradient"
                            coloredShadow={icon.color || "dark"}
                            borderRadius="xl"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            color="white"
                            mt={-5}
                            mx={2}
                        >
                            <Icon fontSize="medium">{icon.component}</Icon>
                        </MDBox>
                    )}
                    <MDBox mt={icon.component ? -2 : 0}>
                        {title && <MDTypography variant="h6">{title}</MDTypography>}
                        <MDBox mb={2}>
                            <MDTypography component="div" variant="button" color="text">
                                {description}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <MDBox display="flex" alignItems="center" justifyContent="center">
                    <ApexCharts options={options} series={options.series} type="polarArea" />
                </MDBox>
            </MDBox>
        </Card>
    )
}
