

import YE from "assets/images/icons/flags/YE.png";
import US from "assets/images/icons/flags/US.png";
const countryData = [
    {
        name: "اليمن",
        latLng: [15.5527, 48.5164],
        flag: YE
    }
]
export default countryData;