import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";

import OrderList from "layouts/ecommerce/orders/order-list";
import SignInBasic from "layouts/authentication/sign-in/basic";
import MenuIcon from '@mui/icons-material/Menu';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

import Icon from "@mui/material/Icon"

const routes = [
  {
    type: "collapse",
    name: "profile",
    key: "profile",
    icon: "",
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/profile-overview",
        component: <ProfileOverview />,
        role: [1, 2, 3]
      },
      {
        name: "Logout",
        key: "logout",
        route: "/auth/sign-in",
        component: <SignInBasic />
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium"><MenuIcon /></Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
        role: [1, 2, 3]
      }
    ],
  },
  {
    type: "collapse",
    name: "User Management",
    key: "user-manage",
    route: "/user-manage",
    component: <OrderList />,
    icon: <Icon fontSize="medium"><ManageHistoryIcon /></Icon>,
    noCollapse: true,
    role: [1]
  },
];

export default routes;
