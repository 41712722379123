// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadgeDot from "components/MDBadgeDot";
import PieChart from "examples/Charts/PieChart";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

function ChannelsChart({ channelChartData, t }) {
    const [controller] = useMaterialUIController();

    return (
        <Card sx={{ height: "100%" }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <MDTypography variant="h6"> {t("Project Status Analysis")}</MDTypography>
            </MDBox>
            <MDBox mt={3}>
                <Grid container alignItems="center" style={{ display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                    <Grid style={{width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexWrap: 'wrap'}}>
                        <MDBox>
                            <MDBadgeDot color="info" size="sm" badgeContent={t("Started")} />
                        </MDBox>
                        <MDBox>
                            <MDBadgeDot color="primary" size="sm" badgeContent={t("Processing")} />
                        </MDBox>
                        <MDBox>
                            <MDBadgeDot color="success" size="sm" badgeContent={t("Soft launch")} />
                        </MDBox>
                    </Grid>
                    <Grid item lg={7}>
                        <PieChart chart={channelChartData} height={200} />
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default ChannelsChart;
