import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './locales/en.json'
import ar from './locales/ar.json'

export const resources = {
    en: {
        translation: en
    },
    ar: {
        translation: ar
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("i18n") || 'ar',
        fallbackLng: 'ar',
        interpolation: {
            escapeValue: false
        }
    })

export default i18n