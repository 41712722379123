// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import { useDispatch, useSelector } from "react-redux"
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import PolarChart from "examples/Charts/PolarChart";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import RadarChart from "examples/Charts/RadarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import ProjectStatus from "./components/ProjectStatus"
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getOriginData } from "store/slices/sheet";
import ApexPolarCharts from "examples/Charts/ApexPolarCharts";
const monthNameData = ["", "Jan", "Feb", "Mat", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function Analytics() {
  const { i18n, t } = useTranslation()
  const dispatch = useDispatch()
  const sheetData = useSelector(state => state.sheet)
  const [reportsBarChartData, setReportsBarChartData] = useState({
    labels: [],
    datasets: { label: "", data: [] },
  })
  const [reportsLineChartData, setReportsLineChartData] = useState({
    labels: [],
    datasets: { label: "", data: [] },
  })
  const [channelChartData, setChannelChartData] = useState({
    labels: [],
    datasets: {
      label: "",
      backgroundColors: [],
      data: [],
    },
  })
  const [reportStatusData1, setReportStatusData1] = useState({
    labels: [t("Before"), t("Delayed"), t("Not Received"), t("Scheduled")],
    datasets: {
      label: t("Projects"),
      backgroundColors: ["info", "error", "secondary", "success"],
      data: [15, 20, 12, 60],
    },
  })
  const [reportStatusData2, setReportStatusData2] = useState({
    labels: [t("Before"), t("Delayed"), t("Not Received"), t("Scheduled")],
    datasets: {
      label: t("Projects"),
      backgroundColors: ["info", "error", "secondary", "success"],
      data: [15, 20, 12, 60],
    },
  })
  const [reportStatusData3, setReportStatusData3] = useState({
    labels: [t("Before"), t("Delayed"), t("Not Received"), t("Scheduled")],
    datasets: {
      label: t("Projects"),
      backgroundColors: ["info", "error", "secondary", "success"],
      data: [15, 20, 12, 60],
    },
  })
  const [employeeData, setEployeeData] = useState({
    labels: [],
    datasets: {
      label: "",
      data: [],
      backgroundColors: ["info", "primary", "dark", "secondary", "success", "warning"],
    },
  })
  const [moneyRaisedData, setMoneyRaisedData] = useState({
    labels: [t("Oct"), t("Nov"), t("Dec"), t("Jan"), t("Feb"), t("Mat"), t("Apr"), t("May"), t("Jun"), t("Jul"), t("Aug"), t("Sep")],
    datasets: [],
  })
  const [moneyByPayMethod, setMoneyByPayMethod] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        color: "",
        data: [],
        borderDash: [],
      },

    ],
  })
  useEffect(() => {
    const projectCountByType = sheetData.projectCountByType
    if (projectCountByType) {
      const Data = {
        labels: [],
        datasets: { label: t("Projects"), data: [] },
      }
      Object.keys(projectCountByType).forEach(element => {
        Data.labels.push(element)
        Data.datasets.data.push(projectCountByType[element])
      })
      setReportsBarChartData({ ...Data })
    }
    const projectCountByStatus = sheetData.projectCountByStatus
    if (projectCountByStatus) {
      const Data = {
        labels: [t("Started"), t("Processing"), t("Soft Launch")],
        datasets: {
          label: t("Projects"),
          backgroundColors: ["info", "primary", "success"],
          data: [],
        },
      }
      Data.datasets.data.push(projectCountByStatus["افتتاح"])
      Data.datasets.data.push(projectCountByStatus["قيد التنفيذ"])
      Data.datasets.data.push(projectCountByStatus["حجر أساس"])
      setChannelChartData({ ...Data })
    }
    const moneyByYear = sheetData.moneyByYear
    if (moneyByYear) {
      let moneyData = []
      Object.keys(moneyByYear).forEach((key, index) => {
        let tmp = {
          label: key,
          color: ["dark", "info", "primary", "success"][index],
          data: [],
        }
        for (let i = 0; i < 12; i++) {
          const index = String((9 + i) % 12 + 1)
          tmp.data.push(moneyByYear[key][index] === undefined ? 0 : moneyByYear[key][index])
        }
        moneyData.push(tmp)
      })
      let headers = [t("Oct"), t("Nov"), t("Dec"), t("Jan"), t("Feb"), t("Mat"), t("Apr"), t("May"), t("Jun"), t("Jul"), t("Aug"), t("Sep")]
      setMoneyRaisedData({
        labels: headers,
        datasets: moneyData,
      })
    }
    const moneyByMonth = sheetData.moneyByMonth
    if (moneyByMonth) {
      let date = new Date()
      let Data = {
        labels: [],
        datasets: { label: "", data: [] },
      }

      date.setMonth(date.getMonth() - 11)
      for (let i = 0; i < 12; i++) {
        const month = date.getMonth() + 1
        Data.labels.push(t(monthNameData[month === 0 ? 12 : month]))
        if (moneyByMonth[date.getFullYear()] !== undefined && moneyByMonth[date.getFullYear()][month] !== undefined) {
          Data.datasets.data.push(moneyByMonth[date.getFullYear()][month])
        } else {
          Data.datasets.data.push(0)
        }
        date.setMonth(month)
      }
      setReportsLineChartData({ ...Data })

    }
    const reportStatus1 = sheetData.reportStatus1
    if (reportStatus1) {
      setReportStatusData1({
        labels: [t("Before"), t("Delayed"), t("Not Received"), t("Scheduled")],
        datasets: {
          label: t("Projects"),
          backgroundColors: ["info", "error", "secondary", "success"],
          data: [reportStatus1["قبل"], reportStatus1["متأخر"], reportStatus1["لم يصل"], reportStatus1["مجدول"]],
        },
      })
    }
    const reportStatus2 = sheetData.reportStatus2
    if (reportStatus2) {
      setReportStatusData2({
        labels: [t("Before"), t("Delayed"), t("Not Received"), t("Scheduled")],
        datasets: {
          label: t("Projects"),
          backgroundColors: ["info", "error", "secondary", "success"],
          data: [reportStatus2["قبل"], reportStatus2["متأخر"], reportStatus2["لم يصل"], reportStatus2["مجدول"]],
        },
      })
    }
    const reportStatus3 = sheetData.reportStatus3
    if (reportStatus3) {
      setReportStatusData3({
        labels: [t("Before"), t("Delayed"), t("Not Received"), t("Scheduled")],
        datasets: {
          label: t("Projects"),
          backgroundColors: ["info", "error", "secondary", "success"],
          data: [reportStatus3["قبل"], reportStatus3["متأخر"], reportStatus3["لم يصل"], reportStatus3["مجدول"]],
        },
      })
    }
    const employeeCountByName = sheetData.employeeCountByName
    if (employeeCountByName) {
      let tmp = {
        labels: [],
        datasets: {
          label: "",
          data: [],
          backgroundColors: ["info", "primary", "warning", "secondary", "success", "darkGreen"],
        },
      }
      Object.keys(employeeCountByName).forEach(key => {
        tmp.labels.push(key)
        tmp.datasets.data.push(employeeCountByName[key])
      })
      setEployeeData(tmp)
    }
    const employeeCountByMethod = sheetData.employeeCountByMethod
    if (employeeCountByMethod) {
      let tmp = {
        labels: [],
        datasets: [
          {
            label: "",
            color: "dark",
            data: [],
            borderDash: [5, 5],
          },
        ],
      }
      Object.keys(employeeCountByMethod).forEach(key => {
        tmp.labels.push(key)
        tmp.datasets[0].data.push(employeeCountByMethod[key])
      })
      setMoneyByPayMethod(tmp)
    }

  }, [sheetData])

  const { sales, tasks } = reportsLineChartData;

  let projectTypeData = {
    labels: [],
    datasets: [
      {
        label: t("Academy"),
        color: "info",
        data: [],
      },
      {
        label: t("Real Estate"),
        color: "success",
        data: [],
      },
      {
        label: t("Residential"),
        color: "warning",
        data: [],
      },
    ],
  };
  let projectStatusData = {
    labels: [],
    datasets: [
      {
        label: t("Started"),
        color: "info",
        data: [],
      },
      {
        label: t("Processing"),
        color: "success",
        data: [],
      },
      {
        label: t("Soft launch"),
        color: "warning",
        data: [],
      },
    ],
  };
  const country = useSelector(state => state.country)
  if (country.detailData.length > 0) {
    let detailData = country.detailData
    detailData.forEach(item => {
      projectTypeData.labels.push(item.countryData.name)
      projectTypeData.datasets[0].data.push(item.projectType.started)
      projectTypeData.datasets[1].data.push(item.projectType.processing)
      projectTypeData.datasets[2].data.push(item.projectType.softLaunch)

      projectStatusData.labels.push(item.countryData.name)
      projectStatusData.datasets[0].data.push(item.projectStatus.started)
      projectStatusData.datasets[1].data.push(item.projectStatus.processing)
      projectStatusData.datasets[2].data.push(item.projectStatus.softLaunch)
    }

    )
    for (let i = 0; i < 3; i++) {
      projectTypeData.labels.push("")
      projectTypeData.datasets[0].data.push(0)
      projectTypeData.datasets[1].data.push(0)
      projectTypeData.datasets[2].data.push(0)

      projectStatusData.labels.push("")
      projectStatusData.datasets[0].data.push(0)
      projectStatusData.datasets[1].data.push(0)
      projectStatusData.datasets[2].data.push(0)
    }
  }
  useEffect(() => {
    dispatch(getOriginData())
  }, [])
  useEffect(() => {
    const moneyByMonth = sheetData.moneyByMonth
    if (moneyByMonth) {
      let date = new Date()
      let Data = {
        labels: [],
        datasets: { label: "", data: [] },
      }
      date.setMonth(date.getMonth() - 11)
      for (let i = 0; i < 12; i++) {
        const month = date.getMonth() + 1
        Data.labels.push(t(monthNameData[month === 0 ? 12 : month]))
        if (moneyByMonth[date.getFullYear()] !== undefined && moneyByMonth[date.getFullYear()][month] !== undefined) {
          Data.datasets.data.push(moneyByMonth[date.getFullYear()][month])
        } else {
          Data.datasets.data.push(0)
        }
        date.setMonth(month)
      }
      setReportsLineChartData({ ...Data })
      const newDatasets = moneyRaisedData.datasets.map(item => {
        return { color: item.color, label: item.label, data: item.data }
      })
      let headers = [t("Oct"), t("Nov"), t("Dec"), t("Jan"), t("Feb"), t("Mat"), t("Apr"), t("May"), t("Jun"), t("Jul"), t("Aug"), t("Sep")]
      setMoneyRaisedData({
        labels: i18n.language === "ar" ? headers : headers,
        datasets: newDatasets,
      })
    }
    const projectCountByStatus = sheetData.projectCountByStatus
    if (projectCountByStatus) {
      const Data = {
        labels: [t("Started"), t("Processing"), t("Soft Launch")],
        datasets: {
          label: t("Projects"),
          backgroundColors: ["info", "primary", "success"],
          data: [],
        },
      }
      Data.datasets.data.push(projectCountByStatus["افتتاح"])
      Data.datasets.data.push(projectCountByStatus["قيد التنفيذ"])
      Data.datasets.data.push(projectCountByStatus["حجر أساس"])
      setChannelChartData({ ...Data })
    }
  }, [i18n.language])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {/* <Grid container style={{ marginBottom: 50 }}>
          <AnalyticData />
        </Grid> */}
        <Grid container>
          <Grid item xs={12}>
            <SalesByCountry />
          </Grid>
        </Grid>
        <MDBox mt={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <Grid container spacing={2} mt={{ lg: "17px", xl: "2px" }} className="board-grid">
                <Grid item xs={12} lg={12} sm={6}>
                  <MDBox>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title=""
                      count={sheetData.totalMoney ? sheetData.totalMoney.toLocaleString() : ""}
                      percentage={{
                        color: "success",
                        amount: "",
                        label: t("Total Money Raised"),
                      }}
                    /></MDBox>
                </Grid>
                <Grid item xs={12} lg={12} sm={6}>
                  <MDBox mt={{ lg: "11px", xl: "11px" }}>
                    <ComplexStatisticsCard
                      color="primary"
                      icon="person_add"
                      title=""
                      count={sheetData.totalProject ? sheetData.totalProject : ""}
                      percentage={{
                        color: "success",
                        amount: "",
                        label: t("Total Number of projects"),
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={9}>
              <MDBox mt={{ xl: 2, md: 4, xs: 6 }}>
                <ReportsLineChart
                  color="dark"
                  title={t("Donations Per Month")}
                  description="Last Campaign Performance"
                  date=""
                  chart={reportsLineChartData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              <ProjectStatus channelChartData={channelChartData} t={t} />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6} mt={{ md: 4, xl: 0, xs: 4 }}>
              <ReportsBarChart
                color="info"
                title={t("Donations By Project Type")}
                description=""
                date=""
                chart={reportsBarChartData}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={6}>
              <ApexPolarCharts
                series={employeeData.datasets.data}
                icon={{ color: "info", component: "leaderboard" }}
                title={t("Employees Performance Overview")}
                description=" "
                legends={employeeData.labels}
              />
              {/* <PolarChart
                icon={{ color: "info", component: "leaderboard" }}
                title={t("Employees Performance Overview")}
                description=" "
                chart={{
                  labels: employeeData.labels,
                  datasets: {
                    label: "",
                    data: [13, 5, 15, 10, 5],
                    backgroundColors: ["info", "primary", "dark", "secondary", "success", "warning"],
                  },
                }}
                t={t}
              /> */}
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <RadarChart
                icon={{ color: "info", component: "leaderboard" }}
                title={t("Payment Method")}
                description=" "
                chart={moneyByPayMethod}
                t={t}
                height={300}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <MDBox mt={6}>
                <DefaultDoughnutChart
                  icon={{ color: "info", component: "leaderboard" }}
                  title={t("1st Report Status")}
                  description=" "
                  t={t}
                  chart={reportStatusData1}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <MDBox mt={6}>
                <DefaultDoughnutChart
                  icon={{ color: "info", component: "leaderboard" }}
                  title={t("2nd Report Status")}
                  description=" "
                  chart={reportStatusData2}
                  t={t}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <MDBox mt={6}>
                <DefaultDoughnutChart
                  icon={{ color: "info", component: "leaderboard" }}
                  title={t("3rd Report Status")}
                  description=" "
                  chart={reportStatusData3}
                  t={t}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>

          <MDBox mt={6}>
            <GradientLineChart
              icon={{ color: "info", component: "leaderboard" }}
              title={t("Donations Annual Overview")}
              description=" "
              chart={moneyRaisedData}
            />
          </MDBox>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
