

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import breakpoints from "assets/theme/base/breakpoints";

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import backgroundImage from "assets/images/bg-profile.jpeg";
import defaultAvatar from "assets/images/avatar/default_avatar.png"

import toast from 'react-hot-toast';
import { updateProfile } from "store/slices/user";
import { useDispatch } from "react-redux";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 4,
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function Header({ profile, children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [file, setFile] = useState(null)
  const dispatch = useDispatch()


  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const [editUserData, setEditUserData] = useState({ name: "", email: "", mobileNumber: "", password: "", confirmPassword: "", file: null })
  const [editUserError, setEditUserError] = useState({ name: false, email: false, mobileNumber: false, password: false })
  const [avatar, setAvatar] = useState("")
  const handleFileChange = (event) => {
    setEditUserData({ ...editUserData, file: event.target.files[0] })
  };
  const handleChange = (event) => {
    const { name, value } = event.target
    setEditUserData({ ...editUserData, [name]: value });
  };
  const handleBlur = (event) => {
    const { name, value } = event.target

    setEditUserError({ ...editUserError, [name]: value ? false : true });
  };
  const handleUpdateUser = () => {
    setEditUserData({ name: profile.name, email: profile.email, mobileNumber: profile.mobileNumber, password: "", confirmPassword: "", file: null })
    setEditUserError({ name: false, email: false, mobileNumber: false, password: false, role: false })
    setOpen(true)
  }
  const handleImageDelete = () => {
    setEditUserData({ ...editUserData, file: null })
    setAvatar("")
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (editUserError.name || editUserError.email || editUserError.mobileNumber || editUserError.password) {
      toast.error("Input data correctly!")
      return
    }
    if (editUserData.password !== editUserData.confirmPassword) {
      setEditUserError({ ...editUserError, password: true })
      return
    } else {
      setEditUserError({ ...editUserError, password: false })
    }
    if (!/\S+@\S+\.\S+/.test(editUserData.email)) {
      setEditUserError({ ...editUserError, email: true })
      return
    }
    const formData = new FormData();
    formData.append("name", editUserData.name);
    formData.append("email", editUserData.email);
    formData.append("mobileNumber", editUserData.mobileNumber);
    formData.append("password", editUserData.password);
    formData.append("file", editUserData.file);
    formData.append("avatar", avatar);
    dispatch(updateProfile(formData))
    handleClose()
  }
  useEffect(() => {

    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  useEffect(() => {
    setAvatar(profile?.avatar)
  }, [profile])
  return (
    <MDBox position="relative" mb={5}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ display: "flex" }}>
                <label style={{ display: "flex" }}>
                  <div variant="contained" component="span">
                    <input
                      accept="*"
                      style={{ display: 'none' }}
                      id="file-upload"
                      type="file"
                      onChange={handleFileChange}
                    />
                    {/* <MDAvatar src={file ? URL.createObjectURL(file) : profile?.avatar?"http://localhost:5000/assets/avatar/Layer%204.png":defaultAvatar} alt="profile-image" size="xl" shadow="sm" style={{ cursor: "pointer" }} /> */}
                    <MDAvatar src={editUserData.file ? URL.createObjectURL(editUserData.file) : avatar ? process.env.REACT_APP_SERVER_URL + "/assets/avatar/" + profile.avatar : defaultAvatar} alt="profile-image" size="xl" shadow="sm" style={{ cursor: "pointer" }} />
                  </div>


                </label>
                {
                  (editUserData.file !== null || avatar) && (<div style={{ position: "relative" }}>
                    <div style={{ position: "absolute", bottom: "-10px", cursor: 'pointer', right: '-5px' }} onClick={handleImageDelete}>
                      <RemoveCircleIcon />
                    </div>
                  </div>)
                }

              </div>

            </div>
            <form action="">
              <TextField
                error={editUserError.name}
                label="Name"
                sx={{ marginTop: 2 }}
                name="name"
                value={editUserData.name}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              <TextField
                error={editUserError.email}
                label="Email"
                value={editUserData.email}
                name="email"
                type="email"
                sx={{ marginTop: 2 }}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              <TextField
                error={editUserError.mobileNumber}
                label="Mobile Number"
                name="mobileNumber"
                type="number"
                value={editUserData.mobileNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ marginTop: 2 }}
                fullWidth
              />
              <TextField
                error={editUserError.password}
                label="New Password"
                name="password"
                value={editUserData.password}
                sx={{ marginTop: 2 }}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                fullWidth
                autoComplete=""
              />
              <TextField
                error={editUserError.password}
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                value={editUserData.confirmPassword}
                sx={{ marginTop: 2 }}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                autoComplete=""
              />
            </form>
            <div style={{ marginTop: 20, display: "flex", justifyContent: "space-around" }}>
              <MDButton sx={{ textTransform: "capitalize", fontSize: "16px" }} onClick={handleClose} variant="gradient" color="info">Cancel</MDButton>
              <MDButton sx={{ textTransform: "capitalize", fontSize: "16px" }} onClick={handleSubmit} variant="gradient" color="info">Confirm</MDButton>
            </div>
          </Box>
        </Fade>
      </Modal>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <div>
              <label htmlFor="file-upload">
                <div variant="contained" component="span">
                  {/* <MDAvatar src={file ? URL.createObjectURL(file) : profile?.avatar?"http://localhost:5000/assets/avatar/Layer%204.png":defaultAvatar} alt="profile-image" size="xl" shadow="sm" style={{ cursor: "pointer" }} /> */}
                  <MDAvatar src={profile?.avatar ? process.env.REACT_APP_SERVER_URL + "/assets/avatar/" + profile.avatar : defaultAvatar} alt="profile-image" size="xl" shadow="sm" />
                </div>
              </label>
            </div>

          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {profile ? profile.name : ""}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {profile ? profile.role === 1 ? "Admin" : profile.role === 2 ? "Supervisors" : "View" : ""}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid sx={{ ml: "auto" }}>
            <MDTypography variant="body2" color="secondary" style={{ float: "right", cursor: "pointer" }} onClick={handleUpdateUser}>
              <Tooltip title="Edit" placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
