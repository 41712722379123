import { useSelector } from "react-redux"
import { useEffect, useState } from "react";
import { VectorMap } from "@react-jvectormap/core";
import { worldMerc } from "@react-jvectormap/world";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import SalesTable from "examples/Tables/SalesTable";
import countryBaseData from "layouts/dashboards/sales/data/countryData";
import { useTranslation } from "react-i18next";

function SalesByCountry() {

  const { t } = useTranslation()
  const countryStoreData = useSelector(state => state.sheet.byCountryData)
  // const countryStoreData = countryDetailData.length > 0 ? countryDetailData.map(item => item.countryData) : []
  const [tableData, setTableData] = useState([])
  const [mapData, setMapData] = useState([])
  useEffect(() => {
    if (countryStoreData.length > 0) {
      const salesTableData = countryStoreData.map(item => {
        let baseData = countryBaseData.filter(base => base.name === item["country_name"])[0]
        return { country: [baseData.flag, item["country_name"]], Projects: item["country"]["Sales"], Money: item["country"]["Values"], Started: item["country"]["Bonunce"] }
      })
      const mapdata1 = countryStoreData.map(item => {
        const baseData = countryBaseData.filter(base => base.name === item["country_name"])[0]
        return {
          name: item["country_name"],
          latLng: baseData.latLng,
        }
      })
      setTableData(salesTableData)
      setMapData(mapdata1)
    }
  }, [countryStoreData])

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="success"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            language
          </Icon>
        </MDBox>
        <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
          {t("Donations by country")}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={12} md={7} lg={6}>
            {tableData.length > 0 ? (<SalesTable rows={tableData} shadow={false} t={t} />) : ""}
          </Grid>
          <Grid item xs={12} md={5} lg={6} sx={{ mt: { xs: 5, lg: 0 } }}>
            {mapData.length > 0 ? (<VectorMap
              map={worldMerc}
              zoomOnScroll={false}
              zoomButtons={false}
              markersSelectable
              backgroundColor="transparent"
              selectedMarkers={[0, 0]}
              markers={mapData}
              regionStyle={{
                initial: {
                  fill: "#dee2e7",
                  "fill-opacity": 1,
                  stroke: "none",
                  "stroke-width": 0,
                  "stroke-opacity": 0,
                },
              }}
              markerStyle={{
                initial: {
                  fill: "#e91e63",
                  stroke: "#ffffff",
                  "stroke-width": 5,
                  "stroke-opacity": 0.5,
                  r: 7,
                },
                hover: {
                  fill: "E91E63",
                  stroke: "#ffffff",
                  "stroke-width": 5,
                  "stroke-opacity": 0.5,
                },
                selected: {
                  fill: "E91E63",
                  stroke: "#ffffff",
                  "stroke-width": 5,
                  "stroke-opacity": 0.5,
                },
              }}
              style={{
                marginTop: "-1.5rem",
                height: "200px"
              }}
              onRegionTipShow={() => false}
              onMarkerTipShow={() => true}
            />) : ""}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default SalesByCountry;
