import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from 'react-hot-toast';
import axios from "axios";
import { updateToken } from "./auth";
export const initialState = {
    profile: null,
    users: null,
    message: null
};

export const getProfile = createAsyncThunk(
    "user/profile",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.get("/api/user/profile");
            if (response.data.success) {
                const profile = response.data.profile;
                return { profile };
            } else {
                toast.error(response.data.message)
                return rejectWithValue(response.data);
            }
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const getUsers = createAsyncThunk(
    "user/all",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.get("/api/user/all");
            if (response.data.success) {
                const users = response.data.users;
                return { users };
            } else {
                toast.error(response.data.message)
                return rejectWithValue(response.data);
            }
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const addUser = createAsyncThunk(
    "auth/signUp",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post("/api/auth/register", data);
            if (response.data.success) {
                toast.success(response.data.message)
                return { user: response.data.user }
            } else {
                toast.error(response.data.message)
                return rejectWithValue(response.data);
            }
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const updateUser = createAsyncThunk(
    "user/update",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put("/api/user", data);
            if (response.data.success) {
                toast.success(response.data.message)
                return { user: response.data.user }
            } else {
                toast.error(response.data.message)
                return rejectWithValue(response.data);
            }
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const deleteUser = createAsyncThunk(
    "user/delete",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete("/api/user/" + data.id);
            if (response.data.success) {
                toast.success(response.data.message)
                return { id: data.id }
            } else {
                toast.error(response.data.message)

                return rejectWithValue(response.data);
            }
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const updateProfile = createAsyncThunk(
    "auth/updateProfile",
    async (data, { dispatch }) => {
        try {
            const response = await axios.put("/api/user/profile", data);
            if (response.data.success) {
                toast.success(response.data.message)
                await dispatch(updateToken({ user: response.data.user, token: response.data.token }))
                return { user: response.data.user, token: response.data.token }
            } else {
                toast.error(response.data.message)
            }
        } catch (err) {
        }
    }
);
export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.fulfilled, (state, action) => {
                state.profile = action.payload.profile;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.users = action.payload.users;
            })
            .addCase(addUser.fulfilled, (state, action) => {
                const users = state.users
                users.push(action.payload.user)
                state.users = users;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                const user = action.payload.user
                const users = state.users.map(item => {
                    if (item._id === user._id) {
                        return user
                    }
                    return item
                })
                state.users = users;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                const users = state.users.filter(item => item._id !== action.payload.id)
                state.users = users;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.profile = action.payload.user;
            })
    },
});

// export const { signOut } = userSlice.actions;

export default userSlice.reducer;
