import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const initialState = {
    reportStatus1: null,
    reportStatus2: null,
    reportStatus3: null,
    detailData: [],
    employee: null
};

export const countrySlice = createSlice({
    name: "country",
    initialState,
    reducers: {
        setCountryData: (state, action) => {
            state.countryData = action.payload.data
        },
        setDetailData: (state, action) => {
            state.reportStatus1 = action.payload.reportStatus1
            state.reportStatus2 = action.payload.reportStatus2
            state.reportStatus3 = action.payload.reportStatus3
            state.detailData = action.payload.detailData
            state.employee = action.payload.employee
        },
    }
});

export const { setCountryData, setDetailData } = countrySlice.actions;

export default countrySlice.reducer;
