
import { useEffect, useState } from "react";
// import jwt from "jsonwebtoken"
// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signIn } from "../../../../store/slices/auth";
import { useTranslation } from "react-i18next";

function Basic() {
  // const [rememberMe, setRememberMe] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const auth = useSelector((state) => state.auth)
  const { t } = useTranslation()
  useEffect(() => {
    if (auth.token) {
      navigate("/dashboards/analytics")
    }
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    formData[name] = value;
    setFormData({ ...formData });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signIn(formData));
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("Sign in")}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {t("Sign in detail")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput name="email" type="email" label={t("Email")} value={formData.email} onChange={handleChange} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput name="password" type="password" label={t("Password")} value={formData.password} onChange={handleChange} fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography> */}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                {t("Sign in")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
