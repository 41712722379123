import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// @mui material components
import Card from "@mui/material/Card";


import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TextField from '@mui/material/TextField';
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
// // Data
// import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import { getUsers, updateUser, deleteUser, addUser } from "store/slices/user";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import toast from 'react-hot-toast';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 4,
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function OrderList() {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleClose = () => setOpen(false);
  const user = useSelector((state) => state.user)
  const [users, setUsers] = useState([])
  const dispatch = useDispatch()
  const [editId, setEditId] = useState("")
  const [editUserData, setEditUserData] = useState({ name: "", email: "", mobileNumber: "", password: "", confirmPassword: "", role: 2 })
  const [editUserError, setEditUserError] = useState({ name: false, email: false, mobileNumber: false, password: false, role: false })
  const { t, i18n } = useTranslation();
  const handleChange = (event) => {
    const { name, value } = event.target
    setEditUserData({ ...editUserData, [name]: value });
  };
  const handleBlur = (event) => {
    const { name, value } = event.target

    setEditUserError({ ...editUserError, [name]: value ? false : true });
  };
  const handleNewUser = () => {
    setEditUserData({ name: "", email: "", mobileNumber: "", password: "", confirmPassword: "", role: 2 })
    setEditUserError({ name: false, email: false, mobileNumber: false, password: false, role: false })
    setEditId("")
    setOpen(true)
  }
  const handleEdit = (id) => {
    setEditId(id)
    setOpen(true)
  }
  const handleDelete = (id) => {
    setEditId(id)
    setOpenConfirm(true)
  }

  const [dataTableData, setDataTableData] = useState({
    columns: [
      { Header: t("id"), accessor: "id", Cell: ({ value }) => <IdCell id={String(value)} /> },
      {
        Header: t("Name"),
        accessor: "name",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: t("Email"),
        accessor: "email",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: t("Role"),
        accessor: "role",
        Cell: ({ value }) => <DefaultCell value={value === 1 ? "Admin" : value === 2 ? "Supervisors" : "View"} />,
      },
      {
        Header: t("Actions"),
        accessor: "actions",
        Cell: ({ value }) => (
          <Stack direction="row" spacing={2}>

            <Button sx={{ background: "white", color: "green" }} onClick={() => handleEdit(value)} variant="outlined" startIcon={<SendIcon />}>
              {t("Edit")}
            </Button>
            <Button sx={{ color: "#000000" }} variant="outlined" onClick={() => handleDelete(value)} startIcon={<DeleteIcon />}>
              {t("Delete")}
            </Button>
          </Stack>
        ),
      }
    ],
    rows: [],
  });
  useEffect(() => {
    dispatch(getUsers())

  }, [])
  useEffect(() => {
    setDataTableData({
      ...dataTableData, columns: [
        { Header: t("id"), accessor: "id", Cell: ({ value }) => <IdCell id={String(value)} /> },
        {
          Header: t("Name"),
          accessor: "name",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("Email"),
          accessor: "email",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("Role"),
          accessor: "role",
          Cell: ({ value }) => <DefaultCell value={value === 1 ? "Admin" : value === 2 ? "Supervisors" : "View"} />,
        },
        {
          Header: t("Actions"),
          accessor: "actions",
          Cell: ({ value }) => (
            <Stack direction="row" spacing={2}>

              <Button sx={{ background: "white", color: "green" }} onClick={() => handleEdit(value)} variant="outlined" startIcon={<SendIcon />}>
                {t("Edit")}
              </Button>
              <Button sx={{ color: "#000000" }} variant="outlined" onClick={() => handleDelete(value)} startIcon={<DeleteIcon />}>
                {t("Delete")}
              </Button>
            </Stack>
          ),
        }
      ],
    })
  }, [i18n.language])
  const handleDeleteSubmit = () => {
    setOpenConfirm(false)
    dispatch(deleteUser({ id: editId }))
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (editId) {
      dispatch(updateUser({ id: editId, role: editUserData.role }))
    } else {
      if (editUserError.name || editUserError.email || editUserError.mobileNumber || editUserError.password) {
        toast.error("Input data correctly!")
        return
      }
      if (editUserData.password !== editUserData.confirmPassword) {
        setEditUserError({ ...editUserError, password: true })
        return
      } else {
        setEditUserError({ ...editUserError, password: false })
      }
      if (!/\S+@\S+\.\S+/.test(editUserData.email)) {
        setEditUserError({ ...editUserError, email: true })
        return
      }
      dispatch(addUser(editUserData))
    }
    handleClose()
  }
  useEffect(() => {

    const temp = user.users ? user.users.map((item, index) => {
      return { id: index + 1, name: item.name, email: item.email, role: item.role, actions: item._id }
    }) : []
    setUsers(temp)
    setDataTableData({
      ...dataTableData, rows: temp
    })
  }, [user])
  useEffect(() => {
    const editData = user.users ? user.users.filter(item => item._id === editId) : []
    if (editData.length > 0) {
      setEditUserData({ name: editData[0].name, email: editData[0].email, mobileNumber: editData[0].mobileNumber, password: "", confirmPassword: "", role: editData[0].role })
      setEditUserError({ name: false, email: false, mobileNumber: false, password: false, role: false })
    }
  }, [editId])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton onClick={handleNewUser} variant="gradient" color="info">
            {t("new user")}
          </MDButton>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography sx={{ textAlign: "center" }} variant="h4" component="h2">
                  {editId ? "Edit User" : "New User"}
                </Typography>
                <TextField
                  error={editUserError.name}
                  label="Name"
                  sx={{ marginTop: 2 }}
                  name="name"
                  value={editUserData.name}
                  disabled={editId ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                <TextField
                  error={editUserError.email}
                  label="Email"
                  value={editUserData.email}
                  name="email"
                  type="email"
                  disabled={editId ? true : false}
                  sx={{ marginTop: 2 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                <TextField
                  error={editUserError.mobileNumber}
                  label="Mobile Number"
                  name="mobileNumber"
                  type="number"
                  value={editUserData.mobileNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ marginTop: 2 }}
                  disabled={editId ? true : false}
                  fullWidth
                />
                <FormControl sx={{ marginTop: 2 }} fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    error={editUserError.role}
                    sx={{ height: 45 }}
                    name="role"
                    value={editUserData.role}
                    label="Role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value={2}>Supervisor</MenuItem>
                    <MenuItem value={3}>View</MenuItem>
                  </Select>
                </FormControl>
                {editId ? "" : <>
                  <TextField
                    error={editUserError.password}
                    label="Password"
                    name="password"
                    value={editUserData.password}
                    sx={{ marginTop: 2 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    fullWidth
                  />
                  <TextField
                    error={editUserError.password}
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    value={editUserData.confirmPassword}
                    sx={{ marginTop: 2 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  /></>}
                <div style={{ marginTop: 20, display: "flex", justifyContent: "space-around" }}>
                  <MDButton sx={{ textTransform: "capitalize", fontSize: "16px" }} onClick={handleClose} variant="gradient" color="info">Cancel</MDButton>
                  <MDButton sx={{ textTransform: "capitalize", fontSize: "16px" }} onClick={handleSubmit} variant="gradient" color="info">Confirm</MDButton>
                </div>
              </Box>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openConfirm}
            onClose={() => setOpenConfirm(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={openConfirm}>
              <Box sx={style}>
                <Typography sx={{ textAlign: "center" }} variant="h4" component="h2">
                  Do you really want to delete?
                </Typography>
                <div style={{ marginTop: 20, display: "flex", justifyContent: "space-around" }}>
                  <MDButton sx={{ textTransform: "capitalize", fontSize: "12px" }} onClick={() => setOpenConfirm(false)} variant="gradient" color="info">Cancel</MDButton>
                  <MDButton sx={{ textTransform: "capitalize", fontSize: "12px" }} onClick={handleDeleteSubmit} variant="gradient" color="info">Confirm</MDButton>
                </div>
              </Box>
            </Fade>
          </Modal>
        </MDBox>
        <Card>
          <DataTable table={dataTableData} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderList;
