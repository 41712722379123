import { rowIndex } from "./config"
export const checkExist = (data, value) => {
    for (let i = 0; i < data.length; i++) {
        if (data[i][value])
            return data[i]
    }
    return false
}

export const changeData = (data, index) => {
    let result = []
    for (let i = 0; i < data.length; i++) {
        let subData = checkExist(result, data[i][index])
        if (subData) {
            subData[data[i][index]].push(data[i])
        } else {
            let tmp = {}
            tmp[data[i][index]] = [data[i]]
            result.push(tmp)
        }
    }
    return result
}
export const getByCountryDataFromOriginData = (data, projectTypes, employeeNames) => {
    if (data.length > 0) {
        data = changeData(data, rowIndex["Country"])
        const bycountrydata = data.map(item => {
            const result = {}
            const key = Object.keys(item)[0]
            const data = item[key]
            //Project type
            let projectTypeData = {}
            projectTypes.forEach(ptype => {
                projectTypeData[ptype] = 0
            })
            data.forEach(element => {
                projectTypeData[element[rowIndex["Project Type"]]] += 1
            })

            //country
            let Values = 0
            for (let i = 0; i < data.length; i++) {
                Values += parseInt(data[i][rowIndex["Money Raised"]].split(",").join(""))
            }
            const Sales = data.length
            const Bounce = data.filter(element => element[rowIndex["Project Status"]] === "افتتاح").length
            //project status
            const Started = data.filter(element => element[rowIndex["Project Status"]] === "افتتاح").length
            const Processing = data.filter(element => element[rowIndex["Project Status"]] === "قيد التنفيذ").length
            const Softlaunch = data.filter(element => element[rowIndex["Project Status"]] === "حجر أساس").length
            //1st Report Status
            const Before1 = data.filter(element => element[rowIndex["1st Report Status"]] === "قبل").length
            const Delayed1 = data.filter(element => element[rowIndex["1st Report Status"]] === "متأخر").length
            const NotReceived1 = data.filter(element => element[rowIndex["1st Report Status"]] === "لم يصل").length
            const Scheduled1 = data.filter(element => element[rowIndex["1st Report Status"]] === "مجدول").length
            //2nd Report Statu
            const Before2 = data.filter(element => element[rowIndex["2nd Report Status"]] === "قبل").length
            const Delayed2 = data.filter(element => element[rowIndex["2nd Report Status"]] === "متأخر").length
            const NotReceived2 = data.filter(element => element[rowIndex["2nd Report Status"]] === "لم يصل").length
            const Scheduled2 = data.filter(element => element[rowIndex["2nd Report Status"]] === "مجدول").length
            //3rd Report Status
            const Before3 = data.filter(element => element[rowIndex["3rd Report Status"]] === "قبل").length
            const Delayed3 = data.filter(element => element[rowIndex["3rd Report Status"]] === "متأخر").length
            const NotReceived3 = data.filter(element => element[rowIndex["3rd Report Status"]] === "لم يصل").length
            const Scheduled3 = data.filter(element => element[rowIndex["3rd Report Status"]] === "مجدول").length
            //employee
            let employeeData = {}

            employeeNames.forEach(ptype => {
                employeeData[ptype] = 0
            })
            data.forEach(element => {
                employeeData[element[rowIndex["Employee"]]] += 1
            })

            result["country_name"] = key
            result["Project type"] = projectTypeData
            result["country"] = { "Sales": Sales, "Values": Values, "Bonunce": Bounce }
            result["Project Status"] = { "Started": Started, "Processing": Processing, "Soft launch": Softlaunch }
            result["1st Report Status"] = { "Before": Before1, Delayed: Delayed1, "Not Received": NotReceived1, "Scheduled": Scheduled1 }
            result["2nd Report Status"] = { "Before": Before2, Delayed: Delayed2, "Not Received": NotReceived2, "Scheduled": Scheduled2 }
            result["3rd Report Status"] = { "Before": Before3, Delayed: Delayed3, "Not Received": NotReceived3, "Scheduled": Scheduled3 }
            result["Employee"] = employeeData
            return result
        })
        return bycountrydata
    } else {
        return []
    }
}
export const getBaseCount = (data) => {
    let projectTypes = []
    let employeeNames = []
    let totalMoney = 0
    let totalProject = 0
    let projectCountByType = {}
    let employeeCountByName = {}
    let reportStatus1 = { "قبل": 0, "متأخر": 0, "لم يصل": 0, "مجدول": 0 }
    let reportStatus2 = { "قبل": 0, "متأخر": 0, "لم يصل": 0, "مجدول": 0 }
    let reportStatus3 = { "قبل": 0, "متأخر": 0, "لم يصل": 0, "مجدول": 0 }
    let projectCountByStatus = { "افتتاح": 0, "افتتاح": 0, "قيد التنفيذ": 0, "حجر أساس": 0 }
    let moneyByMonth = {}
    let moneyByYear = {}
    let paymentTypes = []
    let employeeCountByMethod = {}

    if (data.length > 0) {
        totalProject = data.length
        for (let i = 0; i < data.length; i++) {
            if (projectTypes.indexOf(data[i][rowIndex["Project Type"]]) == -1) {
                projectTypes.push(data[i][rowIndex["Project Type"]])
                projectCountByType[data[i][rowIndex["Project Type"]]] = 1
            } else {
                projectCountByType[data[i][rowIndex["Project Type"]]] += 1
            }
            if (employeeNames.indexOf(data[i][rowIndex["Employee"]]) == -1) {
                employeeNames.push(data[i][rowIndex["Employee"]])
                employeeCountByName[data[i][rowIndex["Employee"]]] = 1
            } else {
                employeeCountByName[data[i][rowIndex["Employee"]]] += 1
            }
            if (paymentTypes.indexOf(data[i][rowIndex["Pyment Method"]].trim()) == -1) {
                paymentTypes.push(data[i][rowIndex["Pyment Method"]].trim())
                employeeCountByMethod[data[i][rowIndex["Pyment Method"]].trim()] = parseInt(data[i][rowIndex["Money Raised"]].split(",").join(""))
            } else {
                employeeCountByMethod[data[i][rowIndex["Pyment Method"]].trim()] += parseInt(data[i][rowIndex["Money Raised"]].split(",").join(""))
            }
            totalMoney += parseInt(data[i][rowIndex["Money Raised"]].split(",").join(""))
            reportStatus1[data[i][rowIndex["1st Report Status"]]] += 1
            reportStatus2[data[i][rowIndex["2nd Report Status"]]] += 1
            reportStatus3[data[i][rowIndex["3rd Report Status"]]] += 1
            projectCountByStatus[data[i][rowIndex["Project Status"]]] += 1
            const date = new Date(data[i][rowIndex["Pyment Date"]])
            const year = date.getFullYear()
            const month = date.getMonth()
            if (moneyByMonth[String(year)] == undefined) {
                moneyByMonth[String(year)] = {}
            }
            moneyByMonth[String(year)][String(date.getMonth() + 1)] = moneyByMonth[String(year)][String(month + 1)] !== undefined ?
                moneyByMonth[String(year)][String(month + 1)] + parseInt(data[i][rowIndex["Money Raised"]].split(",").join("")) :
                parseInt(data[i][rowIndex["Money Raised"]].split(",").join(""))
            if (month > 8) {
                if (moneyByYear[String(year + 1)] == undefined) {
                    moneyByYear[String(year + 1)] = {}
                }
                moneyByYear[String(year + 1)][String(date.getMonth() + 1)] = moneyByYear[String(year + 1)][String(month + 1)] !== undefined ?
                    moneyByYear[String(year + 1)][String(month + 1)] + parseInt(data[i][rowIndex["Money Raised"]].split(",").join("")) :
                    parseInt(data[i][rowIndex["Money Raised"]].split(",").join(""))
            } else {
                if (moneyByYear[String(year)] == undefined) {
                    moneyByYear[String(year)] = {}
                }
                moneyByYear[String(year)][String(date.getMonth() + 1)] = moneyByYear[String(date.getFullYear())][String(month + 1)] !== undefined ?
                    moneyByYear[String(date.getFullYear())][String(month + 1)] + parseInt(data[i][rowIndex["Money Raised"]].split(",").join("")) :
                    parseInt(data[i][rowIndex["Money Raised"]].split(",").join(""))
            }
        }
    }
    return {
        projectTypes, employeeNames, totalMoney, totalProject, projectCountByType, employeeCountByMethod,
        reportStatus1, reportStatus2, reportStatus3, projectCountByStatus, moneyByMonth, moneyByYear, employeeCountByName
    }
}