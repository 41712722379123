import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode"
import toast from 'react-hot-toast';
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
const AUTH_TOKEN = 'auth_token'
let token = localStorage.getItem(AUTH_TOKEN) || null;
let user = null;
if (token) {
  axios.defaults.headers.common.Authorization = token;
  const decode = jwtDecode(token)
  if (Date.now() < decode.exp * 1000) {
    user = decode.user
  } else {
    token = null
    localStorage.removeItem(AUTH_TOKEN)
  }
}

export const initialState = {
  user,
  token,
  message: null,
  errors: null,
};

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/auth/login", data);
      if (response.data.success) {
        toast.success(response.data.message)
        const token = response.data.token;
        const user = response.data.user;
        axios.defaults.headers.common.Authorization = token;
        localStorage.setItem(AUTH_TOKEN, token);
        return { token, user };
      } else {
        toast.error(response.data.message)
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/auth/register", data);
      if (response.data.success) {
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signOut: (state, action) => {
      toast.success("logout successfuly!")
      localStorage.removeItem(AUTH_TOKEN);
      delete axios.defaults.headers.common.Authorization;
      state.user = null;
      state.token = null;
    },
    updateToken: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem(AUTH_TOKEN, action.payload.token);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload.message
        state.errors = action.payload.errors;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload.message
        state.errors = action.payload.errors
      });
  },
});

export const { signOut, updateToken } = authSlice.actions;

export default authSlice.reducer;
