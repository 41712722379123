import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useTranslation } from "react-i18next";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav } from "context";

import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import SignInBasic from "layouts/authentication/sign-in/basic";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { t } = useTranslation()
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const auth = useSelector((state) => state.auth);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route && route.role && auth.user && route.role.indexOf(auth.user.role) > -1) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return direction === "rtl" ? (
    // <CacheProvider value={rtlCache}>
    <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName={t("International Projects")}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        <Route path="/" element={<Navigate to="/dashboards/analytics" />} />
        {getRoutes(routes)}
        {auth.token ? "" : <Route path="/auth/sign-in" element={<SignInBasic />} />}
        {auth.token ? <Route path="*" element={<Navigate to="/" />} /> : <Route path="*" element={<Navigate to="/auth/sign-in" />} />}
      </Routes>
    </ThemeProvider>
    // </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName={t("International Projects")}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        <Route path="/" element={<Navigate to="/dashboards/analytics" />} />
        {getRoutes(routes)}
        {auth.token ? "" : <Route path="/auth/sign-in" element={<SignInBasic />} />}
        {auth.token ? <Route path="*" element={<Navigate to="/" />} /> : <Route path="*" element={<Navigate to="/auth/sign-in" />} />}
      </Routes>
    </ThemeProvider>
  );
}
