import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from 'react-hot-toast';
import { getByCountryDataFromOriginData, getBaseCount } from "utils/helpers";
export const initialState = {
    originData: [],
    byCountryData: [],
    totalMoney: null,
    projectCountByType: null,
    projectTypes: [],
    employeeNames: [],
    employeeCountByName: null,
    employeeCountByMethod: null,
    totalMoney: null,
    totalProject: null,
    reportStatus1: null,
    reportStatus2: null,
    reportStatus3: null,
    moneyByMonth: null,
    moneyByYear: null,
    projectCountByStatus: null
};


export const getOriginData = createAsyncThunk(
    "sheet/all",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const regex = /\/d\/([a-zA-Z0-9_-]+)/;
            if (process.env.REACT_APP_GOOGLE_SHEET !== "") {
                const match = process.env.REACT_APP_GOOGLE_SHEET?.match(regex)
                const sheetId = match ? match[1] : null
                const RANGE = 'Sheet1!D1:Y';
                const response = await fetch(
                    `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${RANGE}?key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                  );
                  const result = await response.json();
                  const sheetData = result.values.slice(1);
                // const sheetData = fakeData.slice(1)
                return sheetData
            } else {
                toast.info("Please add the google sheet link")
            }
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const sheetSlice = createSlice({
    name: "sheet",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getOriginData.fulfilled, (state, action) => {
                state.originData = action.payload;
                const baseCount = getBaseCount(state.originData)
                state.projectTypes = baseCount.projectTypes
                state.employeeNames = baseCount.employeeNames
                state.totalMoney = baseCount.totalMoney
                state.totalProject = baseCount.totalProject
                state.projectCountByType = baseCount.projectCountByType
                state.reportStatus1 = baseCount.reportStatus1
                state.reportStatus2 = baseCount.reportStatus2
                state.reportStatus3 = baseCount.reportStatus3
                state.projectCountByStatus = baseCount.projectCountByStatus
                state.moneyByMonth = baseCount.moneyByMonth
                state.moneyByYear = baseCount.moneyByYear
                state.employeeCountByName = baseCount.employeeCountByName
                state.employeeCountByMethod = baseCount.employeeCountByMethod
                state.byCountryData = getByCountryDataFromOriginData(state.originData, baseCount.projectTypes, baseCount.employeeNames)
            })
    },
});

export const { setByCountryData } = sheetSlice.actions;

export default sheetSlice.reducer;
