
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";
import { useTranslation } from "react-i18next";

function Footer() {
  const { size } = typography;
  const { t } = useTranslation()

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        {t("@Copyright")} {new Date().getFullYear()}. {t("All Rights Reserved")}.
      </MDBox>
    </MDBox>
  );
}



export default Footer;
