import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/auth";
import user from "./slices/user";
import sheet from "./slices/sheet";
import country from "./slices/country";

const store = configureStore({
  reducer: {
    auth,
    user,
    sheet,
    country
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
